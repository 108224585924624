<template>
  <div>
    <CustomerPayment v-if="isRestaurant" :supplier-id="businessId" />
    <SupplierPayment v-else-if="isSupplier" :customer-id="businessId" />
  </div>
</template>

<script>
import { computed } from 'vue';

import { useTenancy } from '@/modules/auth';
import { BUSINESS_TYPE } from '@/modules/users/constants';

import CustomerPayment from './customer/Payment';
import SupplierPayment from './supplier/Payment';

export default {
  components: { CustomerPayment, SupplierPayment },
  props: { businessId: { type: String, default: null } },
  setup() {
    const { currentTenant } = useTenancy();
    const isRestaurant = computed(() => currentTenant.value?.type === BUSINESS_TYPE.RESTAURANT);
    const isSupplier = computed(() => currentTenant.value?.type === BUSINESS_TYPE.SUPPLIER);

    return { isRestaurant, isSupplier };
  },
};
</script>
