import { Doughnut } from 'vue-chartjs';
import colors from '@/stylesheets/scss/colors.module.scss';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  extends: Doughnut,
  props: {
    chunks: { type: Array, required: true },
  },
  setup() {
    const { formatToCurrency } = useCurrency({ maximumFractionDigits: 0 });

    return { formatToCurrency };
  },
  watch: {
    chunks: {
      deep: true,
      handler(newChunks) {
        if (newChunks.length) {
          this.$data._chart.data.datasets[0].data = newChunks.map((chunk) => chunk?.amount ?? 0);
          this.$data._chart.data.datasets[0].backgroundColor = [
            colors.black,
            colors.doghnutRed,
            colors.doghnutGray,
            colors.informative,
          ];
        } else {
          this.$data._chart.data.datasets[0].data = [100];
          this.$data._chart.data.datasets[0].backgroundColor = [colors.outline];
        }
        this.$data._chart.update();
      },
    },
  },
  methods: {
    formatMoney(value) {
      return this.formatToCurrency(Math.round(value));
    },
  },
  mounted() {
    this.renderChart(
      {
        datasets: [
          {
            data: this.chunks.length ? this.chunks.map((chunk) => chunk?.amount ?? 0) : [100],
            backgroundColor: this.chunks.length
              ? [colors.black, colors.doghnutRed, colors.doghnutGray, colors.informative]
              : [colors.outline],
            hoverBorderWidth: [0, 0],
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: 70,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 10,
          },
        },
        tooltips: {
          display: true,
          displayColors: false,
          bodyAlign: this.$direction === 'rtl' ? 'right' : 'left',
          titleAlign: this.$direction === 'rtl' ? 'right' : 'left',
          custom: (tooltip) => {
            if (tooltip.opacity > 0) {
              this.$emit('tooltip-visible', true);
            } else {
              this.$emit('tooltip-visible', false);
            }
          },
          callbacks: {
            title: (tooltipItem) => {
              if (this.chunks.length) {
                const item = this.chunks[tooltipItem[0].index];
                const translationText = this.$t(`payment.monthlyPaymentOverview.chart.${item.label}`).split(' ');
                if (translationText.length > 3)
                  return [
                    translationText.slice(0, 2).join(' '),
                    translationText.slice(2, translationText.length).join(' '),
                  ];
                return this.$t(`payment.monthlyPaymentOverview.chart.${item.label}`);
              }
              return this.$t('payment.monthlyPaymentOverview.chart.noInvoices');
            },

            label: (tooltipItem) => {
              if (this.chunks.length) {
                return this.$t('payment.monthlyPaymentOverview.chart.invoiceCount', {
                  count: this.chunks[tooltipItem.index].count,
                });
              }
              return null;
            },
            afterLabel: (tooltipItem) => {
              if (this.chunks.length) {
                return this.$t('payment.monthlyPaymentOverview.chart.invoiceTotalAmount', {
                  amount: this.formatMoney(this.chunks[tooltipItem.index].amount),
                });
              }
              return null;
            },
          },
        },
      }
    );
  },
};
