import { gql } from '@apollo/client/core';
import { map, prop } from 'ramda';
import { useQuery } from '@vue/apollo-composable';
import { computed, reactive } from 'vue';

import { useNotification, useLoading } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

export const useEvents = (billingIds, paymentIds, orderIds, deliveryIds) => {
  const { currentTenant } = useTenancy();
  const {
    billings,
    refetch: billingsRefetch,
    loading: billingsLoading,
  } = useBillings(
    computed(() => currentTenant.value.id),
    billingIds
  );
  const {
    payments,
    refetch: paymentsRefetch,
    loading: paymentsLoading,
  } = usePayments(
    computed(() => currentTenant.value.id),
    paymentIds
  );
  const {
    orders,
    refetch: ordersRefetch,
    loading: ordersLoading,
  } = useOrders(
    computed(() => currentTenant.value.id),
    orderIds
  );
  const {
    deliveries,
    refetch: deliveriesRefetch,
    loading: deliveriesLoading,
  } = useDeliveries(
    computed(() => currentTenant.value.id),
    deliveryIds
  );

  const refetch = () => {
    paymentsRefetch();
    billingsRefetch();
    ordersRefetch();
    deliveriesRefetch();
  };

  return {
    events: computed(() => map(prop('value'))({ billings, payments, deliveries, orders })),
    loading: computed(
      () => billingsLoading.value || paymentsLoading.value || ordersLoading.value || deliveriesLoading.value
    ),
    refetch,
  };
};

export function useBillings(businessId, ids) {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(
    BILLING_QUERY,
    {
      businessId,
      ids,
    },
    () => ({ enabled: !!ids.value.length && !!businessId.value })
  );
  const billings = computed(() => result.value?.billings?.nodes ?? []);
  const variables = reactive({ businessId, ids });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('Payment activity useBillings', err);
    error();
  });

  return {
    billings,
    loading,
    refetch,
  };
}

function usePayments(businessId, ids) {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(PAYMENTS_QUERY, { businessId, ids }, () => ({
    enabled: !!ids.value.length && !!businessId.value,
  }));
  const payments = computed(() => result.value?.payments?.nodes ?? []);
  const variables = reactive({ businessId, ids });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('Payment activity usePayments', err);
    error();
  });

  return {
    payments,
    loading,
    refetch,
  };
}

function useOrders(businessId, ids) {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(ORDERS_NEW_QUERY, { businessId, ids }, () => ({
    enabled: !!ids.value.length && !!businessId.value,
  }));

  const orders = computed(() => result.value?.orders?.nodes ?? []);
  const variables = reactive({ businessId, ids });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('Payment activity useOrders', err);
    error();
  });

  return {
    orders,
    loading,
    refetch,
  };
}

function useDeliveries(businessId, ids) {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(DELIVERIES_QUERY, { businessId, ids }, () => ({
    enabled: !!ids.value.length && !!businessId.value,
  }));
  const deliveries = computed(() => result.value?.deliveries?.nodes ?? []);
  const variables = reactive({ businessId, ids });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('Payment activity useDeliveries', err);
    error();
  });

  return {
    deliveries,
    loading,
    refetch,
  };
}

const BILLING_QUERY = gql`
  query getBillings($businessId: ID!, $ids: [ID]) {
    billings(businessId: $businessId, ids: $ids) {
      nodes {
        id
        date
        supplierId
        type
        taxAmount
        netAmount
        totalAmount
        orderLinks {
          order {
            id
          }
        }
        deliveryRefs {
          delivery {
            id
          }
        }
        imbalances(resolved: "all") {
          orderedAmounts {
            id
            amount
          }
          billedAmounts {
            id
            amount
          }
          resolved
        }
        eventReferences {
          documentId
        }
      }
    }
  }
`;

const PAYMENTS_QUERY = gql`
  query getPayments($ids: [ID!], $businessId: ID!) {
    payments(ids: $ids, businessId: $businessId) {
      nodes {
        id
        requestedDate
        date
        amount
        isRefund
        transactions {
          paymentMethod
          reference
        }
        eventReferences {
          documentId
        }
      }
    }
  }
`;

const ORDERS_NEW_QUERY = gql`
  query getOrders($ids: [ID!], $businessId: ID!) {
    orders(ids: $ids, businessId: $businessId) {
      nodes {
        id
        date
        supplierId
        netAmount
        products {
          productId
        }
        differences {
          customerItemId
          amount
          pricingAmount
          quantityAmount
          product {
            id
            name
            sku
          }
          quantity {
            supplierValue
            customerValue
            solved
          }
          price {
            supplierValue
            customerValue
            solved
          }
          discount {
            supplierValue
            customerValue
            solved
          }
          netPrice {
            supplierValue
            customerValue
            solved
          }
        }
        source {
          type
          ref
        }
        eventReferences {
          reference
          documentId
        }
      }
    }
  }
`;

const DELIVERIES_QUERY = gql`
  query getDeliveries($ids: [ID!], $businessId: ID!) {
    deliveries(ids: $ids, businessId: $businessId) {
      nodes {
        id
        date
        supplierId
        products {
          productId
          quantity
        }
        diffs {
          supplierValue
          customerValue
          resolved
        }
        imbalances {
          productId
        }
        source {
          type
          ref
        }
        eventReferences {
          reference
          documentId
        }
      }
    }
  }
`;
