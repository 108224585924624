<template>
  <el-popover
    v-if="isPaymentDueNetDefined"
    :value="show"
    placement="bottom"
    trigger="manual"
    popper-class="text-typography-primary p-2"
  >
    <Button
      slot="reference"
      type="link"
      class="p-0"
      :class="`text-${PAYMENT_STATUS_COLORS[paymentStatus[0]]} ${PAYMENT_STATUS_COLORS[paymentStatus[0]]}`"
      @click.stop="$emit('click')"
    >
      <div class="d-flex" style="gap: 2px">
        <p class="warning-button">{{ formatDate(paymentDueDate) }}</p>
      </div>
    </Button>
    <div class="pb-1 ps-1">
      <div v-if="paymentStatus[0] !== PAYMENT_STATUS.UNPAID" class="d-flex gap-1">
        <AlertIcon :class="`text-${PAYMENT_STATUS_COLORS[paymentStatus[0]]}`" />
        <div class="pt-1">
          <p class="fw-bold">{{ $t(`payment.paymentTable.paymentStatus.${paymentStatus[0]}`) }}</p>
          <small>
            <i18n :path="`payment.paymentTable.overduePopover.${paymentStatus[0]}`">
              <template #date>
                <small v-if="paymentStatus[0] === PAYMENT_STATUS.OVERDUE" class="fw-bold">
                  {{ getPastDaysNumber(paymentDueDate) }}
                </small>
                <small v-if="paymentStatus[0] === PAYMENT_STATUS.APPROACHING_DUE" class="fw-bold">
                  {{ getApproachingDaysNumber(paymentDueDate) }}
                </small>
              </template>
            </i18n>
          </small>
        </div>
      </div>
      <hr v-if="paymentStatus[0] !== PAYMENT_STATUS.UNPAID" class="my-2" />
      <div class="d-flex gap-1">
        <TermsAndAgreementsIcon />
        <div class="pt-1">
          <p class="fw-bold">{{ $t('payment.paymentTable.paymentTerms') }}</p>
          <small>
            {{
              $tc(
                `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
                paymentTerm.paymentDueNet,
                { count: paymentTerm.paymentDueNet }
              )
            }}
          </small>
        </div>
      </div>
    </div>
  </el-popover>
  <div v-else>
    <Button
      v-if="actionAllowed"
      :disabled="disabled"
      type="link"
      class="fw-normal p-0"
      @click.stop="$emit('open-modal')"
    >
      <p>{{ $t('payment.paymentTable.notDefined') }}</p>
    </Button>
    <p v-else>{{ $t('payment.paymentTable.notDefined') }}</p>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import { Button } from '@/modules/core';
import { AlertIcon, TermsAndAgreementsIcon } from '@/assets/icons';

import { PAYMENT_STATUS, PAYMENT_STATUS_COLORS } from '../types';
import { formatDate } from '../tools/formatters';

export default {
  components: {
    Button,
    AlertIcon,
    TermsAndAgreementsIcon,
  },
  props: {
    paymentDueDate: { type: String, default: null },
    paymentTerm: { type: Object, default: () => null },
    paymentStatus: { type: Array, default: () => [] },
    show: { type: Boolean, default: false },
    actionAllowed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup() {
    return {
      formatDate,
      PAYMENT_STATUS_COLORS,
      PAYMENT_STATUS,
    };
  },
  computed: {
    isPaymentDueNetDefined() {
      return this.paymentTerm && Number.isInteger(this.paymentTerm.paymentDueNet);
    },
  },
  methods: {
    getPastDaysNumber(paymentDueDate) {
      const { days, months, years } = DateTime.local()
        .diff(DateTime.fromISO(paymentDueDate).toUTC(), ['days', 'months', 'years'])
        .toObject();
      if (years) return this.$tc('payment.paymentTable.overduePopover.years', years, { count: Math.round(years) });
      if (months) return this.$tc('payment.paymentTable.overduePopover.months', months, { count: Math.round(months) });
      if (days) return this.$tc('payment.paymentTable.overduePopover.days', days, { count: Math.round(days) });
    },
    getApproachingDaysNumber(paymentDueDate) {
      const { days } = DateTime.local().diff(DateTime.fromISO(paymentDueDate).toUTC(), ['days']).toObject();
      return this.$tc('payment.paymentTable.overduePopover.days', days, { count: Math.round(days) });
    },
  },
};
</script>
