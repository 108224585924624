<template>
  <div class="d-flex align-items-center gap-1">
    <template v-if="paymentTerm && paymentTerm.paymentMethod">
      <div class="position-relative d-flex align-self-center">
        <el-tooltip
          :content="getTooltipContent(paymentTerm)"
          placement="right"
          effect="dark"
          :open-delay="500"
          class="lh-1"
        >
          <span>
            <RecurringIcon
              v-if="paymentTerm.directDebit"
              class="position-absolute badge-position"
              width="14"
              height="14"
            />
            <BankTransferIcon
              v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER"
              width="16"
              height="16"
            />
            <ChequeIcon v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CHEQUE" width="16" height="16" />
            <CreditCardIcon
              v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD"
              width="16"
              height="16"
            />
          </span>
        </el-tooltip>
      </div>
    </template>
    <Button
      v-else-if="actionAllowed"
      :disabled="disabled"
      type="link"
      class="fw-normal p-0 add-btn"
      @click.stop="$emit('open-payment-method-modal')"
    >
      <NewIcon />
    </Button>
    <div v-if="isPaymentDueNetDefined" class="w-100">
      <TruncatedText>
        {{
          $tc(
            `payment.paymentTable.paymentTermsPopover.paymentDueNet${paymentTerm.eom ? 'Eom' : ''}`,
            paymentTerm.paymentDueNet,
            {
              count: paymentTerm.paymentDueNet,
            }
          )
        }}</TruncatedText
      >
    </div>
    <div v-else>
      <Button
        v-if="actionAllowed"
        :disabled="disabled"
        type="link"
        class="fw-normal p-0 m-0"
        @click.stop="$emit('open-payment-due-modal')"
      >
        <p>{{ $t('payment.paymentTable.notDefined') }}</p>
      </Button>
      <p v-else>{{ $t('payment.paymentTable.notDefined') }}</p>
    </div>
  </div>
</template>

<script>
import { Button, TruncatedText } from '@/modules/core';
import { CreditCardIcon, ChequeIcon, BankTransferIcon, RecurringIcon, NewIcon } from '@/assets/icons';

import { PAYMENT_METHOD_TYPE } from '../types';

export default {
  components: { Button, CreditCardIcon, ChequeIcon, BankTransferIcon, RecurringIcon, NewIcon, TruncatedText },
  props: {
    paymentTerm: { type: Object, default: () => null },
    actionAllowed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  setup() {
    return { PAYMENT_METHOD_TYPE };
  },
  computed: {
    isPaymentDueNetDefined() {
      return this.paymentTerm && Number.isInteger(this.paymentTerm.paymentDueNet);
    },
  },
  methods: {
    getTooltipContent(term) {
      const paymentMethod = this.$t(`payment.exports.paymentMethods.shortName.${term.paymentMethod}`);

      if (term.directDebit) {
        const directDebit = this.$t('payment.paymentTable.directDebit');
        return `${paymentMethod} - ${directDebit}`;
      }

      return paymentMethod;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.badge-position {
  top: 25%;
  [dir='rtl'] & {
    left: 100%;
    transform: translate(-50%, -50%);
  }
  [dir='ltr'] & {
    left: 0;
    transform: translate(-50%, -50%) scaleX(-1);
  }
}
</style>
