import { Bar, mixins } from 'vue-chartjs';

import { fontFamilyBase } from '@/stylesheets/scss/fonts.module.scss';
import colors from '@/stylesheets/scss/colors.module.scss';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const { formatToCurrency } = useCurrency({ minimumFractionDigits: 0 });
    return { formatToCurrency };
  },
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 10,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: { display: false },
            ticks: {
              fontColor: colors.black,
              fontSize: 10,
              fontFamily: fontFamilyBase,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            position: this.$direction === 'rtl' ? 'right' : 'left',
            gridLines: { display: true, drawBorder: false, zeroLineColor: 'rgba(0, 0, 0, 0.1)' },
            ticks: {
              fontColor: colors.black,
              fontSize: 10,
              fontFamily: fontFamilyBase,
              beginAtZero: true,
              maxTicksLimit: 4,
              callback: (value) => {
                return this.formatMoney(value);
              },
            },
          },
        ],
      },
      tooltips: {
        display: true,
        displayColors: false,
        position: 'nearest',
        intersect: false,
        mode: 'index',
        filter: (tooltipItem, data) => (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? true : false),
        callbacks: {
          title: () => {},
          label: (tooltipItem, data) =>
            `${data.datasets[tooltipItem.datasetIndex].label}: ${this.formatToCurrency(tooltipItem.yLabel, {
              maximumFractionDigits: 2,
            })}`,
        },
      },
      legend: {
        display: this.showLegend,
        position: 'bottom',
        align: 'start',
        rtl: this.$direction === 'rtl' ? true : false,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 4,
          fontColor: colors.black,
          fontSize: 12,
          fontFamily: fontFamilyBase,
        },
      },
    });
  },
  methods: {
    formatMoney(value) {
      const valueDivided = value / 1000;

      const formatUnits = [this.formatToCurrency(valueDivided), 'k'];
      if (this.$direction === 'rtl') formatUnits.reverse();
      return formatUnits.join('');
    },
  },
};
